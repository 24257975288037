import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import { hideVisually, darken } from "polished";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: frontmatter.title }
  ];
  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={frontmatter.title}
      preambleText={frontmatter.preambleText}
      metaDescription={frontmatter.metaDescription}
    >
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
}

const Content = styled.div`
  max-width: 60ch;

  ul {
    li {
      margin-top: 0.5em;

      p {
        margin: 0;
      }
    }
  }
/* 
  table {
    margin-top: 1.5rem;
    border-radius: 1rem;
    border-collapse: collapse;
    font-size: 0.8em;
    background-color: ${({ theme }) => theme.palette.grey};

    tr {
      > th,
      > td {
        padding: 0.5rem;
        text-align: left;
        border: 2px solid ${({ theme }) => theme.palette.dark};
      }
    }
  } */

  table {
    width: 100%;
    margin-top: 1.5rem;
    border-radius: 1rem;
    border-collapse: collapse;
    overflow: hidden;
    font-size: 0.8em;
    background-color: ${({ theme }) => theme.palette.grey};

    @media (min-width: 40em) {
      width: auto;
    }

    tr {
      > th,
      > td {
        padding: 0.5rem;
        text-align: left;
        border: 1px solid ${({ theme }) => theme.palette.dark};
      }
    }

    > thead {
      @media (max-width: 40em) {
        ${hideVisually};
      }
    }

    > tbody {

      > tr {
        display: flex;
        flex-direction: column;

        @media (min-width: 40em) {
          display: table-row;
        }

        > th {
          display: block;
          width: 100%;
          border-top: 3px solid ${({ theme }) => theme.palette.dark};
          text-align: center;

          @media (min-width: 40em) {
            display: table-cell;
            width: 20%;
            border-top: none;
            text-align: left;
          }
        }

        > td {
          display: flex;
          width: 100%;

          @media (max-width: 40em) {

            &:before {
              content: attr(data-th);
              display: block;
              text-align: left;
            }
          }

          @media (min-width: 40em) {
            display: table-cell;
            width: auto;

            &:before {
              content: none;
              display: none;
            }
          }
        }

        &:nth-child(2n+1) {
          > td,
          > th {
            background-color: ${({ theme }) =>
              darken("0.025", theme.palette.grey)};
          }
        }
      }
    }

    caption {
      ${hideVisually};
    }
  }

  .responsive-table {

    > tbody {

      > tr {

        > td {

          @media (max-width: 40em) {

            &:before {
              content: attr(data-th);
              display: block;
              flex-grow: 1;
              text-align: left;
            }
          }
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        preambleText
        metaDescription
      }
    }
  }
`;
